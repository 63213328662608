// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-bathrooms-js": () => import("./../../../src/pages/gallery/bathrooms.js" /* webpackChunkName: "component---src-pages-gallery-bathrooms-js" */),
  "component---src-pages-gallery-exteriors-js": () => import("./../../../src/pages/gallery/exteriors.js" /* webpackChunkName: "component---src-pages-gallery-exteriors-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-gallery-interiors-js": () => import("./../../../src/pages/gallery/interiors.js" /* webpackChunkName: "component---src-pages-gallery-interiors-js" */),
  "component---src-pages-gallery-kitchens-js": () => import("./../../../src/pages/gallery/kitchens.js" /* webpackChunkName: "component---src-pages-gallery-kitchens-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

